import React from 'react'
import {Link, useLocation} from 'react-router-dom';
import i18n from '../../../i18n';
import useQuery from '../../../useQuery';
import {FormattedMessage} from 'react-intl';
import {DEFAULT_SERVICE_DESCRIPTION_SEPARATOR} from '../../../constants';
import getTagValue from '../../../utilities/get-tag-value';
import {format} from 'date-fns';
import getServiceUrl from '../../../utilities/get-service-url';
import classNames from 'classnames';
import IconFeature from '../../../components/icon-feature/icon-feature';
import CalendarMinus01Icon from '../../../assets/icons0/calendar-minus-01.svg';

const ServiceListItem = React.memo(({service}) => {
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    const tags = service.description ? service.description.split(DEFAULT_SERVICE_DESCRIPTION_SEPARATOR) : [];
    const targetEndVal = getTagValue(tags, 'targetEnd');
    const target = service.target ? format(new Date(service.target), 'dd-MM-yyyy') : null;
    const targetEnd = targetEndVal ? format(new Date(targetEndVal), 'dd-MM-yyyy') : null;
    const lowestTicketPrice = React.useMemo(() => {
        service.endpoints ? service.endpoints.map(e => e.price).sort() : [];
        if (service.endpoints && service.endpoints.length) {
            return service.endpoints.reduce((price, endpoint) => {
                price = price > endpoint.price ? endpoint.price : price;
                return price;
            }, service.endpoints[0].price);
        }
    }, [service]);
    const serviceUrl = getServiceUrl({description: service.description, id: service.id, slug: service.slug, warp: service.warp, location});
    return (
        <div className='space-endpoint border-radius-0dot75rem border-1px-solid padding-1dot25rem mw768-padding-2rem'>
            <div className='display-flex flex-direction-column-row'>
                <div className=' mw768-flex-1 mw768-margin-right-1rem'>
                    <div className='space-endpoint__title text-lg medium mw768-text-xl'>
                        {i18n(service, 'name', locale)}
                    </div>
                    {
                        target &&
                            <div className='space-endpoint__date text-sm mw768-text-md padding-top-0dot5rem'>
                                <span>{target}</span>
                                {targetEnd && target !== targetEnd &&
                                    <span className='padding-left-0dot25rem'>{` — ${targetEnd}`}</span>
                                }
                            </div>
                    }

                </div>
                <div className='display-flex mw768-flex-1 justify-content-space-between align-items-center mw768-align-self-center mw768-justify-content-flex-end padding-top-1rem mw768-padding-top-0'>
                    <p className='space-endpoint__price mw768-margin-right-2dot5rem'><FormattedMessage defaultMessage='from'/> {lowestTicketPrice} UAH</p>
                    <div className='width-7rem min-height-2dot625rem'>
                        <Link to={serviceUrl} className='space-endpoint__btn border-1px-solid padding-0dot5rem border-radius-0dot5rem text-md medium display-flex position-relative justify-content-center shadow-xs space-endpoint__btn--empty cursor-pointer width-100percent'>
                            <span className='add-to-cart--visible'><FormattedMessage defaultMessage='To event'/></span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
});

const ServiceList = ({list, theme}) => {
    return (
        <>
            <div className='padding-bottom-1dot5rem display-flex justify-content-space-between align-items-center'>
                <div className='display-flex'>
                    <h2 className={classNames('margin-left-0dot75rem display-xs semibold', {'color-gray-200' : theme == 'dark', 'color-gray-700' : theme == 'light'})}><FormattedMessage defaultMessage='Choose event' /></h2>
                </div>
            </div>
            {list.map(service => (
                <div key={service.id} className='padding-bottom-1dot5rem'>
                    <ServiceListItem {...{service}}/>
                </div>
            ))}
            {!list.length && 
                <div className={classNames('padding-top-1rem padding-bottom-1rem padding-left-1dot5rem padding-right-1dot5rem border-radius-0dot75rem', {'background-color-gray-900': theme === 'dark', 'background-color-white': theme === 'light'})}>
                    <div className='display-flex flex-direction-column align-items-center'>
                        <IconFeature {...{size: 'md', color: theme === 'light' ? 'primary' : 'gray-dark', feature: 'outline'}}>
                            <CalendarMinus01Icon/>
                        </IconFeature>
                        <div className={classNames('text-md padding-top-0dot75rem text-align-center', {'color-gray-100': theme === 'dark', 'color-gray-500': theme === 'light'})}>
                            <span><FormattedMessage defaultMessage='There are no events at this time'/></span>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};

export default React.memo(ServiceList);