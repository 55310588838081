import React from 'react';
import './Shell.scss';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import MinusCircleSvg from '../../../assets/icons/minus-circle.svg';
import PlusCircleSvg from '../../../assets/icons/plus-circle.svg';
import MessangerGroupe from '../../../assets/messenger-groupe.svg';
// import LogoDarkModeFalseSvg from '../../../assets/logo-dark-mode-false.png';
import {ServiceListHomePage} from './service-list';
import {LinkButton} from '../../button/button';
import Skeleton from '../../Skeleton';
import TopProjects from './top-projects/top-projects.component';
import MainTitleBg from '../../../assets/main-title-bg.svg';
import {TELEGRAM_SUPPORT} from '../../../constants';
import {Helmet} from 'react-helmet-async';
import CtaSectionSellOnline from './cta-section-sell-online';
import SearchLgSvg from '../../../assets/icons0/search-lg.svg';
import XCircleSvg from '../../../assets/icons0/x-circle.svg';
import AlgoliaLogoBlueSvg from '../../../assets/algolia-logo-blue.svg';
import searchFilter from '../../../searchFilter';
import { useForm } from 'react-hook-form';

const facebookLink = 'https://www.facebook.com/OTTRYcom';

export const Faq = React.memo(() => {
    const [a, setA] = React.useState();
    const toggleA = React.useCallback(() => setA((a) => !a), []);
    const [b, setB] = React.useState();
    const toggleB = React.useCallback(() => setB((b) => !b), []);
    return (
        <div className='background-gradient-faq'>
            <div className='view'>
                <div className='block'>
                    <div className='padding-left-0-14rem padding-right-0-14rem'>
                        <React.Suspense fallback={
                            <div className='margin-top-4rem'>
                                <Skeleton/>
                            </div>
                        }>
                            <div className='padding-top-4rem display-sm semibold color-gray-900 text-align-center'>
                                <FormattedMessage defaultMessage='Frequently asked questions'/>
                            </div>
                            <div className='margin-top-1rem text-lg color-gray-500 text-align-center'>
                                <FormattedMessage defaultMessage='Answers to frequently asked questions can be found on the'/>
                                {' '}
                                <Link className='color-primary-500'to='/faq'>F.A.Q</Link>
                                {' '}
                                <FormattedMessage defaultMessage='page' />
                            </div>
                            <div className='margin-top-2rem'>
                                <div className='display-flex justify-content-space-between'>
                                    <div>
                                        <div className='text-lg medium color-gray-900'>
                                            <FormattedMessage defaultMessage='How to get a receipt?'/>
                                        </div>
                                        {a &&
                                        <div className='text-md color-gray-500 margin-top-0dot5rem'>
                                            <FormattedMessage defaultMessage='After a successful transaction, we will automatically send you an email with all the details, and you can always use your Ottry personal account.'/>
                                        </div>
                                        }
                                    </div>
                                    <div className='margin-left-0dot5rem'>
                                        <div className='min-height-1dot75rem display-flex align-items-center'>
                                            <div onClick={toggleA} className='cursor-pointer'>
                                                {a ?
                                                    <MinusCircleSvg className='display-block btn-icon--primary height-1dot5rem width-1dot5rem'/>
                                                    :
                                                    <PlusCircleSvg className='display-block btn-icon--primary height-1dot5rem width-1dot5rem'/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='margin-top-2rem background-color-gray-200 height-0dot0625rem'/>
                            <div className='margin-top-1dot5rem'>
                                <div className='display-flex justify-content-space-between'>
                                    <div>
                                        <div className='text-lg medium color-gray-900'>
                                            <FormattedMessage defaultMessage='Can I exchange or return?'/>
                                        </div>
                                        {b &&
                                        <div className='text-md color-gray-500 margin-top-0dot5rem'>
                                            <FormattedMessage defaultMessage='Ottry provides a platform for vendors. Unfortunately, the platform does not currently support automatic returns, and we are already working on implementing this functionality. Therefore for return and exchange, contact the seller immediately using contact details on the sales page.' />
                                            <br/><br/>
                                            <FormattedMessage defaultMessage='With regard to the exchange and return of the seller, sellers must work in accordance with the Law of Ukraine on Consumer Protection, which regulates the conditions of return, the possibility of return, fixes the list of goods not subject to return, and more.' />
                                        </div>
                                        }
                                    </div>
                                    <div className='margin-left-0dot5rem'>
                                        <div className='min-height-1dot75rem display-flex align-items-center'>
                                            <div onClick={toggleB} className='cursor-pointer'>
                                                {b ?
                                                    <MinusCircleSvg className='display-block btn-icon--primary height-1dot5rem width-1dot5rem'/>
                                                    :
                                                    <PlusCircleSvg className='display-block btn-icon--primary height-1dot5rem width-1dot5rem'/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Suspense>
                    </div>
                    <div className='display-none margin-top-3rem background-color-gray-50 min-height-5rem border-radius-1rem'>
                        <div className='padding-left-1dot25rem-14rem padding-right-1dot25rem-14rem'>
                            <React.Suspense fallback={
                                <div className='padding-top-2rem padding-bottom-2rem'>
                                    <Skeleton/>
                                </div>
                            }>
                                <div className='padding-top-2rem display-flex justify-content-center'>
                                    <MessangerGroupe/>
                                </div>
                                <div className='text-xl medium color-gray-900 text-align-center'>
                                    <FormattedMessage defaultMessage='Questions left?'/>
                                </div>
                                <div className='text-md color-gray-500 margin-top-0dot5rem text-align-center'>
                                    <FormattedMessage defaultMessage={`Didn't find the answer, have a complaint or a wish?`}/>
                                </div>
                                <div className='text-align-center margin-top-1dot5rem padding-bottom-2rem'>
                                    <LinkButton link={{target: '_blank', rel: 'noopener noreferrer', href: TELEGRAM_SUPPORT}} color='primary-gradient' size='lg' >
                                        <FormattedMessage defaultMessage='Contact us'/>
                                    </LinkButton>
                                </div>
                            </React.Suspense>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

const RAZZLE_APP_ORIGIN = process.env.RAZZLE_APP_ORIGIN;

const hlmtImage = `${RAZZLE_APP_ORIGIN}/homepage-social-card.jpg`;
const hlmtSiteName = 'Ottry';
const hlmtUrl = RAZZLE_APP_ORIGIN;

const config = {shouldValidate: true, shouldDirty: true};
const Search = React.memo(() => {
    const location = useLocation();
    const {register, handleSubmit, setFocus, setValue, watch} = useForm();
    const contain = watch('contain');
    const navigate = useNavigate();
    const submit = handleSubmit(data => {
        navigate(`./events${searchFilter(location.search, {contain: data.contain}, false)}`);
    });
    return (
        <div className='position-relative background-color-gray-50'>
            <div className='padding-top-2rem mw768-padding-top-4rem'>
                <div className='view'>
                    <div className='block'>
                        <div className='display-flex'>
                            <div className='flex-1 mw768-flex-grow-0 mw768-flex-shrink-0 mw768-flex-basis-50percent'>
                                <form onSubmit={submit}>
                                    <div className='form_beta'>
                                        <div className='margin-top-0dot375rem'>
                                            <div className='input background-color-white border-1px-solid border-color-gray-300 border-radius-0dot5rem display-flex align-items-center padding-left-0dot875rem padding-top-0dot625rem padding-bottom-0dot625rem padding-right-0dot875rem'>
                                                <SearchLgSvg
                                                    className='color-gray-500 cursor-pointer display-block width-1dot25rem height-1dot25rem'
                                                    onClick={() => {
                                                        submit();
                                                    }}
                                                />
                                                <div className='flex-1 padding-left-0dot5rem padding-right-0dot5rem'>
                                                    <input
                                                        className='width-100percent text-md color-gray-500 display-block'
                                                        type='text'
                                                        placeholder='Search'
                                                        {...register('contain')}
                                                    />
                                                </div>
                                                {contain && 
                                                <XCircleSvg
                                                    className='color-gray-500 cursor-pointer display-block width-1dot25rem height-1dot25rem'
                                                    onClick={() => {
                                                        setValue('contain', '', config);
                                                        setFocus('contain');
                                                    }}
                                                />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='display-flex padding-top-0dot25rem'>
                            <div className='flex-1 mw768-flex-grow-0 mw768-flex-shrink-0 mw768-flex-basis-50percent'>
                                <div className='display-flex justify-content-flex-end'>
                                    <AlgoliaLogoBlueSvg
                                        className='display-block'
                                        style={{width: 52.7, height: 12}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default React.memo(() => {
    return (
        <>
        <Helmet>
            <meta property='og:image' content={hlmtImage}/>
            <meta property='og:site_name' content={hlmtSiteName}/>
            <meta property='og:url' content={hlmtUrl}/>
        </Helmet>
        <div className='position-relative'>
            <div className='block padding-bottom-4rem-6rem main-title--block'>
                <div className='margin-top-4rem mw768-margin-top-6rem display-flex main-title--container'>
                    <div className='flex-1 display-flex padding-right-0-4rem'>
                        <div>
                            <div className='margin-top-0dot75rem display-md semibold color-gray-900 mw768-display-xl letter-spacing-minus-0dot04rem'><h1><FormattedMessage defaultMessage='Buy, attend, enjoy!'/></h1></div>
                            <div className='margin-top-1dot5rem text-lg mw768-text-xl mw1024-padding-bottom-14rem color-gray-500'><FormattedMessage defaultMessage='Buy tickets online - quickly, conveniently and safely.' /></div>
                        </div>
                    </div>
                    <div className='flex-1'>
                        <TopProjects/>
                    </div>
                </div>
            </div>
            {/* TODO: перенести в background блока после решения проблемы с обработкой svg */}
            <div className='main-title--bg'>
                <MainTitleBg />
            </div>
        </div>
        <Search />
        <div className='background-color-gray-50 padding-bottom-4rem'>
            <div className='view'>
                <div className='block'>
                    <div className='padding-top-2rem text-sm mw768-text-md semibold color-blue-gray-500'><FormattedMessage defaultMessage='Events | Ottry Tickets'/></div>
                    <div className='margin-top-0dot5rem display-sm mw768-display-lg semibold color-gray-900'><FormattedMessage defaultMessage='Upcoming events'/></div>
                    <div className='margin-top-1dot5rem-2rem'><ServiceListHomePage/></div>
                </div>
            </div>
        </div>
        <Faq/>
        <div className='margin-top-4rem padding-bottom-4rem-6rem gradient-grey-900-600-45deg-gradient-grey-800-700-26dot5deg'>
            <CtaSectionSellOnline/>
        </div>
        </>
    );
});