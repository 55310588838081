import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import useQuery from '../../../useQuery';
import i18n from '../../../i18n';
import splitPrice from '../splitPrice';
import ButtonIcon from '../../../components/button-icon/button-icon.component';
import CartAddCircle from '../../../assets/icons/cart-add-circle.svg';
import CartMinusCircle from '../../../assets/icons/cart-minus-circle.svg';
import getTagValue from '../../../utilities/get-tag-value';

export const OrderItemRequestData = React.memo(({endpointId, serviceId, children}) => {
    const {endpointById, serviceById} = useLazyLoadQuery(
        graphql`
            query orderItemImpulseCheckoutPagesQuery($endpointId: String, $serviceId: String) {
                endpointById(id: $endpointId) {
                    id
                    name
                    nameUk
                    price
                    tags
                }
                serviceById(id: $serviceId) {
                    id
                    name
                    nameUk
                    target
                }
            }
        `,
        {endpointId: endpointId, serviceId: serviceId}
    ); 
    return (
        <>
            {children({serviceById, endpointById})}
        </>
    )
});

export const OrderItemDynamicQuantity = React.memo(({service, name, price, untie, quantity, nameUk, dispatch, stateKey, tags}) => {
    const {locale = 'uk'} = useQuery();
    const splittedPrice = splitPrice(untie ? untie.price : price);
    const action = (type) => {
        dispatch({type, payload: stateKey});
    };
    const targetDate = getTagValue(tags, 'targetDate');
    return (
        <>
            <div className='checkout-order-item padding-top-1rem padding-bottom-1rem'>
                <div className='flex-1 padding-right-1rem'>
                    <p className='text-md color-gray-700'>{i18n(service, 'name', locale)} | {i18n({name, nameUk}, 'name', locale)}</p>
                    {targetDate ?
                        <p className='text-md color-gray-500 margin-top-0dot25rem'>{targetDate}</p>
                        :
                        moment(service.target).isValid() && <p className='text-md color-gray-500 margin-top-0dot25rem'>{moment(service.target).format('DD.MM.YYYY')}</p>
                    }
                </div>
                <div className='display-flex justify-content-space-between padding-top-0dot5rem'>
                    <div className='display-flex align-items-center'>
                        <ButtonIcon {...{clickHandler: () => action('decrement'), disabled: quantity < 2}}>
                            <CartMinusCircle className='width-100percent'/>
                        </ButtonIcon>
                        <p className='color-primary-600 text-md padding-left-0dot25rem padding-right-0dot25rem min-width-2rem text-align-center'>{quantity}</p>
                        <ButtonIcon {...{clickHandler: () => action('increment')}}>
                            <CartAddCircle className='width-100percent'/>
                        </ButtonIcon>
                    </div>
                    <p className='text-md color-gray-900'>
                        <span>
                            <span>{splittedPrice.whole}</span>
                            {splittedPrice.fraction &&
                                <>
                                    <span>.</span>
                                    <span className='text-xs medium'>{splittedPrice.fraction}</span>
                                </>
                            }
                            <span>{' '}</span>
                        </span>
                        <FormattedMessage defaultMessage='UAH'/>
                    </p>
                </div>
            </div>
            <div className='divider border-bottom-color-gray-200'/>
        </>
    )
});

const OrderItem = ({service, name, price, untie, quantity, nameUk, showOrderItemQuantity, tags}) => {
    const {locale = 'uk'} = useQuery();
    const splittedPrice = splitPrice(untie ? untie.price : price);
    const targetDate = getTagValue(tags, 'targetDate');
    return (
        <>
            <div className='checkout-order-item display-flex padding-top-1rem padding-bottom-1rem'>
                {showOrderItemQuantity && <p className='text-md color-blue-light-600 semibold padding-right-0dot625rem'>{quantity}x</p>}
                <div className='flex-1 padding-right-1rem'>
                    <p className='text-md color-gray-700'>{i18n(service, 'name', locale)} | {i18n({name, nameUk}, 'name', locale)}</p>
                    {targetDate ?
                        <p className='text-md color-gray-500 margin-top-0dot25rem'>{targetDate}</p>
                        :
                        moment(service.target).isValid() && <p className='text-md color-gray-500 margin-top-0dot25rem'>{moment(service.target).format('DD.MM.YYYY')}</p>}
                </div>
                <p className='text-md color-gray-900'>
                    <span>
                        <span>{splittedPrice.whole}</span>
                        {splittedPrice.fraction &&
                            <>
                                <span>.</span>
                                <span className='text-xs medium'>{splittedPrice.fraction}</span>
                            </>
                        }
                        <span>{' '}</span>
                    </span>
                    <FormattedMessage defaultMessage='UAH'/>
                </p>
            </div>
            <div className='divider border-bottom-color-gray-200'/>
        </>
    )
};

export default React.memo(OrderItem);
