import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import './Shell.scss';
import searchFilter from '../../../searchFilter';
import LogoDarkModeFalseSvg from '../../../assets/logo-dark-mode-false.svg';
import Xsvg from '../../../assets/icons/x.svg';
import ChevronDownSvg from '../../../assets/icons/chevron-down.svg';
import ChevronUpSvg from '../../../assets/icons/chevron-up.svg';
import Menu01Svg from '../../../assets/icons0/menu-01.svg';
import MenuSmall from './menu-small';
import MenuLarge from './menu-large';
import {UserMenu, ShoppingBag, User} from './Shell';
import {TELEGRAM_SUPPORT} from '../../../constants';
import {useAuthorizationState} from '../../../contexts/Authorization';
import MenuForPartners from './MenuForPartners';

const Header = ({menuFlag, setMenuFlag}) => {
    const toggleMenu = React.useCallback(() => setMenuFlag((flag) => !flag), []);
    const menuSmallButtonRef = React.useRef();
    const menuSmallRef = React.useRef();
    const menuLargeButtonRef = React.useRef();
    const menuLargeRef = React.useRef();
    const menuForPartnersButtonRef = React.useRef();
    const menuForPartnersRef =  React.useRef();
    const [userMenuFlag, setUserMenuFlag] = React.useState(false);
    const [menuForPartnersFlag, setMenuForPartnersFlag] = React.useState(false);
    const toggleMenuForPartners = React.useCallback(() => setMenuForPartnersFlag((flag) => !flag), []);
    const toggleUserMenu = React.useCallback(() => setUserMenuFlag((flag) => !flag), []);
    const userMenuButtonRef = React.useRef();
    const userMenuRef = React.useRef();
    const authorized = useAuthorizationState();
    React.useEffect(() => {
        const onClickOutsideHandler = (event) => {
            if (menuFlag && !(
                menuSmallButtonRef.current.contains(event.target)
                || menuLargeButtonRef.current.contains(event.target)
                || menuSmallRef.current.contains(event.target)
                || menuLargeRef.current.contains(event.target)
            )) setMenuFlag(false);
            if (userMenuFlag && !(
                userMenuButtonRef.current.contains(event.target)
                || userMenuRef.current.contains(event.target)
            )) setUserMenuFlag(false);
            if (menuForPartnersFlag && !(
                menuForPartnersButtonRef.current.contains(event.target)
                || menuForPartnersButtonRef.current.contains(event.target)
                || menuForPartnersRef.current.contains(event.target)
                || menuForPartnersRef.current.contains(event.target)
            )) setMenuForPartnersFlag(false);
        };
        document.addEventListener('click', onClickOutsideHandler, true);
        return () => {
            document.removeEventListener('click', onClickOutsideHandler, true);
        };
    }, [menuFlag, menuSmallButtonRef, menuLargeButtonRef, menuSmallRef, menuLargeRef, userMenuFlag, userMenuButtonRef, userMenuRef, menuForPartnersFlag, menuForPartnersButtonRef, menuForPartnersRef]);
    const location = useLocation();

    return (
        <>
            <div className='border-bottom-1px-solid border-bottom-color-gray-100'>
                <div className='view'>
                    <div className='padding-left-1dot5rem mw768-padding-left-2rem padding-right-1dot25rem mw768-padding-right-2rem'>
                        <div className='height-4dot5rem display-flex justify-content-space-between align-items-center'>
                            <div className='display-flex'>
                                <Link to={`/${searchFilter(location.search)}`}>
                                    <LogoDarkModeFalseSvg className='display-block width-6dot125rem height-2dot375rem'/>
                                </Link>
                                <div className='hide-small display-flex align-items-center margin-left-5dot25rem text-md medium color-gray-500 position-relative'>
                                    <div className='display-flex align-items-center cursor-pointer' ref={menuLargeButtonRef} onClick={toggleMenu}>
                                        <div><FormattedMessage defaultMessage='Solutions' /></div>
                                        <div>
                                            {menuFlag ?
                                                <ChevronUpSvg className='margin-left-0dot5rem display-block height-1dot25rem width-1dot25rem'/>
                                                :
                                                <ChevronDownSvg className='margin-left-0dot5rem display-block height-1dot25rem width-1dot25rem'/>
                                            }
                                        </div>
                                    </div>
                                    <div className='display-flex margin-left-2rem align-items-center cursor-pointer' ref={menuForPartnersButtonRef} onClick={toggleMenuForPartners}>
                                        <div><FormattedMessage defaultMessage='For partners' /></div>
                                        <div>
                                            {menuForPartnersFlag ?
                                                <ChevronUpSvg className='margin-left-0dot5rem display-block height-1dot25rem width-1dot25rem'/>
                                                :
                                                <ChevronDownSvg className='margin-left-0dot5rem display-block height-1dot25rem width-1dot25rem'/>
                                            }
                                        </div>
                                    </div>
                                    <a className='margin-left-2rem color-gray-500' href={TELEGRAM_SUPPORT} target='_blank'>
                                        <div><FormattedMessage defaultMessage='Support' /></div>
                                    </a>
                                    <div className='hide-small'><MenuLarge {...{menuLargeRef, menuFlag, toggleMenu}}/></div>
                                    <div className='hide-small'><MenuForPartners {...{menuForPartnersRef, menuForPartnersFlag, toggleMenuForPartners}}/></div>
                                </div>
                            </div>
                            <div className='display-flex align-items-center'>
                                <ShoppingBag {...{menuFlag}}/>
                                <div ref={menuSmallButtonRef} className='hide-large height-2dot5rem width-2dot5rem margin-left-0dot5rem display-flex justify-content-center align-items-center'>
                                    {menuFlag ?
                                        <Xsvg className='display-block height-1dot5rem width-1dot5rem color-gray-600' onClick={toggleMenu}/>
                                        :
                                        <Menu01Svg className='display-block height-1dot5rem width-1dot5rem color-gray-600' onClick={toggleMenu}/>
                                    }
                                </div>
                                <User {...{userMenuButtonRef, toggleUserMenu}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <React.Suspense>
                <div className='hide-small'>{authorized && <UserMenu {...{userMenuRef, userMenuFlag, toggleUserMenu}}/>}</div>
            </React.Suspense>
            <div className='hide-large'><MenuSmall {...{menuSmallRef, menuFlag, toggleMenu}}/></div>
        </>
    )
};

export default React.memo(Header);