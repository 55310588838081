import React from 'react';
import {FormattedMessage} from 'react-intl';
import CompleteContainer from '../complete/container';
import CompleteContent from '../complete/content';
import CompleteImpulseStateIcon, {CompleteImpulseErrorStateIcon, CompleteImpulsePendingStateIcon} from '../complete/impulse-state-icon';
import CompleteDescription from '../complete/description';
import CompleteAction, {TicketingCompleteActionError, TicketingCompleteActionSuccess} from '../complete/action';
import CompleteFooter from '../complete/footer';
import useSubscription from '../../../useSubscription';
import useSubscriptionStatus from '../../../useSubscriptionStatus';
import useQuery from '../../../useQuery';
import {CheckoutStateContext} from '../../../components/CheckoutContext';
import {fetchQuery, graphql, useLazyLoadQuery, useRelayEnvironment} from 'react-relay/hooks';
import {IMPULSE_STATUS_GENERALIZED} from '../../../constants';
import getServiceUrl from '../../../utilities/get-service-url';
import classNames from 'classnames';
import Button, {LinkButton} from '../../../components/button/button';
import ArrowBlockDownIcon from '../../../assets/icons0/arrow-block-down.svg';
import './success.scss';

const RAZZLE_APP_PREFIX = process.env.RAZZLE_APP_PREFIX;

const PAYMENT_STATE_DESCRIPTION = {
    success: {
        title: <FormattedMessage defaultMessage='Thank you for your order'/>,
        message: <FormattedMessage defaultMessage='We have sent an email with your order. You can also download your ticket on this page.'/>,
        Icon: CompleteImpulseStateIcon,
        Actions: TicketingCompleteActionSuccess
    },
    pending: {
        title: <FormattedMessage defaultMessage='Payment in progress'/>,
        message: <FormattedMessage defaultMessage='Your payment is being processed by the bank. You will receive your ticket as soon as the payment is made.'/>,
        Icon: CompleteImpulsePendingStateIcon,
        Actions: () => null
    },
    error: {
        title: <FormattedMessage defaultMessage='Payment has not been made'/>,
        message: <FormattedMessage defaultMessage='We were unable to complete the payment. Please try using a different card or contact our support.'/>,
        Icon: CompleteImpulseErrorStateIcon,
        Actions: TicketingCompleteActionError
    }
};

const ImpulseStatusSubscription = React.memo(({claim, setPaymentState}) => {
    const {impulseId} = useQuery();
    const environment = useRelayEnvironment();
    const fetchImpulse = () => {
        fetchQuery(
            environment,  
            graphql`
                query SuccessTicketingStatusImpulseByClaimQuery($claimRequest: ClaimRequestInput) {
                    impulseByClaim(request: $claimRequest) {
                        id
                        claim
                        impulseService {
                            service {
                                id
                                version
                            }
                        }
                        status
                    }
                }
            `, 
            {claimRequest: {value: claim}})
        .subscribe({
            next: (data) => {
                setPaymentState(IMPULSE_STATUS_GENERALIZED[data.impulseByClaim.status]);
            }
        });
    }
    const payload = React.useMemo(() => ({
        channel: `/impulses/${impulseId}`,
        onNext: () => fetchImpulse(),
        onError: () => {}
    }), [fetchImpulse, impulseId]);
    useSubscription(payload);
    const subscriptionStatus = useSubscriptionStatus();
    React.useEffect(() => {
        if (subscriptionStatus === 0) fetchImpulse();
    }, [subscriptionStatus, fetchImpulse]);

    return null;
});

const TicketingSuccessPage = () => {
    const {impulseId} = useQuery();
    const state = React.useContext(CheckoutStateContext);
    const checkoutImpulse = React.useMemo(() => state.find(e => e.id === impulseId), [state, impulseId]);
    const [showDownloadPopUp, setShowDownloadPopUp] = React.useState(false);
    const [filesToDownload, setFilesToDownload] = React.useState([]);
    const {impulseByClaim} = useLazyLoadQuery(
        graphql`
            query SuccessTicketingImpulseByClaimQuery($claimRequest: ClaimRequestInput) {
                impulseByClaim(request: $claimRequest) {
                    id
                    claim
                    impulseClient {
                        client {
                            id
                        }
                    }
                    impulseService {
                        service {
                            id
                            description
                            slug
                            warp
                            version
                        }
                    }
                    qrs
                    status
                }
            }
        `,
        {claimRequest: {value: checkoutImpulse.claim}}
    );
    const [paymentStateDescription, setPaymentStateDescription] = React.useState(PAYMENT_STATE_DESCRIPTION[IMPULSE_STATUS_GENERALIZED[impulseByClaim.status]]);
    const setPaymentState = React.useCallback((state) => {
        setPaymentStateDescription(PAYMENT_STATE_DESCRIPTION[state]);
    }, [setPaymentStateDescription]);
    const linkRef = React.useRef();
    const impulseByAuthorizedUser = impulseByClaim.impulseClient.client && impulseByClaim.impulseClient.client.id;
    const eventLink = getServiceUrl({description: impulseByClaim.impulseService.service.description, id: impulseByClaim.impulseService.service.id, slug: impulseByClaim.impulseService.service.slug, warp: impulseByClaim.impulseService.service.warp, location: {search: ''}});
    const downloadSuccessfullImpulseTickets = React.useCallback(() => {
        const downloadFiles = [];
        impulseByClaim.qrs.forEach((endpoint, index) => {
            endpoint.forEach((qr, qrIndex) => {
                downloadFiles.push({
                    url: `${RAZZLE_APP_PREFIX}/api/impulses/impulse/download?claim=${checkoutImpulse.claim}&i=${index}&j=${qrIndex}`,
                    i: index,
                    j: qrIndex
                });
            });
        });
        setFilesToDownload(downloadFiles);
        if (downloadFiles.length > 1) {
            setShowDownloadPopUp(true);
        } else if (downloadFiles.length === 1) {
            downloadFiles.forEach((file, index) => {
                linkRef.current.href = file.url;
                linkRef.current.setAttribute('target', '_blank');
                linkRef.current.click();
            });
        }
    });

    return (
        <CompleteContainer>
            <CompleteContent>
                <paymentStateDescription.Icon/>
                <CompleteDescription {...{title: paymentStateDescription.title, message: paymentStateDescription.message}}/>
                <paymentStateDescription.Actions {...{downloadSuccessfullImpulseTickets, eventLink, showWalletButtonSuccess: !!impulseByAuthorizedUser}}/>
                {checkoutImpulse && impulseByClaim.status < 3 && <ImpulseStatusSubscription {...{claim: checkoutImpulse.claim, setPaymentState}}/>}
                <a ref={linkRef} className='display-none' target='_blank'/>
            </CompleteContent>
            <CompleteFooter/>
            <div className={classNames('ticketing-success-pop-up', {'ticketing-success-pop-up--visible': showDownloadPopUp})}>
                <div className='ticketing-success-pop-up__container'>
                    <div className='ticketing-success-pop-up__title medium text-sm mw768-text-lg'>
                        <FormattedMessage defaultMessage='Download tickets'/>
                    </div>
                    <div className='ticketing-success-pop-up__content display-flex flex-direction-column row-gap-0dot75rem'>
                        {filesToDownload.map((file, index) => (
                            <LinkButton key={index} {...{color: 'primary-inverse', size: 'lg', fluid: 'always', link: {href: file.url, target: '_blank'}}} >
                                <ArrowBlockDownIcon className='display-block width-1dot25rem height-1dot25rem margin-right-0dot5rem'/>
                                <FormattedMessage defaultMessage='Download ticket'/>
                            </LinkButton>
                        ))}
                        <Button {...{color: 'secondary-gray', size: 'lg', fluid: 'always', clickHandler: () => setShowDownloadPopUp(false)}} >
                            <FormattedMessage defaultMessage='Cancel'/>
                        </Button>
                    </div>
                </div>
            </div>
        </CompleteContainer>
    )
};

export default React.memo(TicketingSuccessPage);
