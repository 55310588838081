import React from 'react'
import {useParams} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {Helmet} from 'react-helmet-async';
import Marked from '../../../components/Marked';
import ErrorFallback from '../../../components/ErrorFallback';
import SuspenseImageDynamicSize from '../../../components/SuspenseImageDynamicSize';
import FacebookIcon from '../../../assets/icons0/socials/facebook-icon.svg';
import InstagramIcon from '../../../assets/icons0/socials/instagram-icon.svg';
import TelegramIcon from '../../../assets/icons0/socials/telegram-icon.svg';
import {FetchKeyStateContext} from '../../../components/FetchKey';
import ServiceList from './service-list';
import NotFoundPage from '../../../components/not-found';
import getTagValue from '../../../utilities/get-tag-value';
import {format, isAfter, isBefore} from 'date-fns';
import CalendarIcon from '../../../assets/icons0/calendar.svg';

const getSpaceMeta = ({name, eventsStart = '', eventsEnd}) => {
    let date = eventsStart;
    if (eventsEnd && eventsStart !== eventsEnd) {
        date = `${date} — ${eventsEnd} `;
    }
    return {
        title: `${name}: Купити квитки на події ${date}| Ottry`,
        description: `${name}: Купити квитки на події ${date}✅ Інформація, розклад подій на ottry.com`
    }
}

const SPACES = {
    'charorchestra': {
        filter: 'slug',
        merchantId: '66154a6381a8c366b1d6544a',
        name: 'Charorchestra',
        theme: 'dark',
        posterUrl: 'ticket.demo.impulse.ottry.com/merchants/61961d86183992456595b779/services/623cd2c174a33242435fa960/ce22ba09-040c-43d1-afe3-2517b222bcc5.jpg',
        details:
`
Char orchestra - колектив молодих музикантів,що складається з солістів провідних національних колективів України. В репертуарі Чару твори від музики епохи бароко,української класики, танго до сучасних рок-хітів голівудських саундтреків. 

«Всім відомі композиції у виконанні Сhar починають звучати по-новому,вони постають у зовсім інших фарбах і тембри академічних інструментів відкривають її нові грані. «Для нас важливо розширити ці горизонти, показати наскільки оркестр глибокий та різноплановий інструмент»
`,
        social: {
            facebook: '',
            instagram: 'https://www.instagram.com/charorchestra',
            telegram: ''
        }
    }
}

const SpaceDescription = ({actionBoxRef}) => {
    const fetchKeyState = React.useContext(FetchKeyStateContext);
    const {space} = useParams();
    const spaceDescription = SPACES[space];
    if (!spaceDescription) {
        return (
            <div className='view theme theme--light'>
                <div className='block'>
                    <div className='margin-0-auto'>
                        <NotFoundPage/>
                    </div>
                </div>
            </div>
        )
    };
    const {viewer} = useLazyLoadQuery(
        graphql`
            query descriptionSpaceServicesByViewerQuery($first: Int!, $after: String) {
                viewer {
                    targetServices(first: $first, after: $after) @connection(key: "descriptionSpace_targetServices") {
                        edges {
                            node {
                                id
                                slug
                                name
                                nameUk
                                warp
                                target
                                description
                                merchant {
                                    id
                                }
                                endpoints {
                                    id
                                    price
                                }
                            }
                        }
                    }
                }
            }
        `,
        {first: 100500},
        {fetchKey: fetchKeyState}
    );
    const {posterUrl, theme, filter, name, details, merchantId} = spaceDescription;
    const {facebook, instagram, telegram} = spaceDescription.social;
    
    const spaceServiceList = React.useMemo(() => {
        if (filter === 'slug') {
            return viewer.targetServices.edges.map(e => e.node).filter(item => {
                return item.merchant.id === merchantId;
            })
        } else if (filter === 'merchantId') {
            return viewer.targetServices.edges.map(e => e.node).filter(item => {
                return item.merchant.id === space;
            })
        } else if (filter === 'spaceName') {
            return viewer.targetServices.edges.map(e => e.node).filter(item => {
                return getTagValue(item.tags, 'space') === space;
            });
        }
        return [];
    }, [viewer, filter, space]);
    const {eventsStartDate, eventsEndDate} = spaceServiceList.reduce((val, service) => {
        if (service.target) {
            const d = new Date(service.target);
            if (val.eventsStartDate) {
                val.eventsStartDate = isBefore(d, val.eventsStartDate) ? d : val.eventsStartDate;
            } else {
                val.eventsStartDate = d;
            }
            if (val.eventsEndDate) {
                val.eventsEndDate = isAfter(d, val.eventsEndDate) ? d : val.eventsEndDate;
            } else {
                val.eventsEndDate = d;
            }
        }
        return val;
    }, {eventsStartDate: null, eventsEndDate: null});
    const eventsStart = eventsStartDate ? format(eventsStartDate, 'dd-MM-yyyy') : null;
    const eventsEnd = eventsEndDate ? format(eventsEndDate, 'dd-MM-yyyy') : null;
    const {title, description} = getSpaceMeta({name, eventsStart, eventsEnd});
    return (
        <>
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description}/>
            {!!posterUrl && <meta property='og:image' content={`https://${posterUrl}`}/>}
        </Helmet>
        <div>
            <div className={`view theme theme--${theme}`}>
                {posterUrl &&
                    <div className=''>
                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                            <SuspenseImageDynamicSize
                                className='space__poster-img display-block max-height-35rem mw768-max-height-37dot5rem margin-0-auto max-width-100vw mw768-max-width-76rem'
                                src={`https://${posterUrl}`}
                            />
                        </ErrorBoundary>
                    </div>
                }
                <div className='block'>
                    <div className='max-width-50rem margin-0-auto'>
                        <h1 className='space__title display-sm semibold padding-top-2rem mw768-padding-top-4rem'>
                            {name}
                        </h1>
                        {eventsStart &&
                            <div className='padding-top-0dot5rem space__target display-flex'>
                                <CalendarIcon className='display-block height-1dot25rem width-1dot25rem'/>
                                <p className='text-md medium padding-left-0dot5rem'>{eventsStart}</p>
                                {eventsEnd && eventsStart !== eventsEnd &&
                                    <span className='text-md medium padding-left-0dot25rem'>{` — ${eventsEnd}`}</span>
                                }
                            </div>
                        }
                        <div className='padding-top-1dot5rem'>
                            <Marked {...{content: details}}/>
                        </div>
                        {(facebook || instagram || telegram) &&
                            <div className='padding-top-1dot25rem mw768-padding-top-3rem display-flex gap-1dot25rem'>
                                {facebook &&
                                    <a href={facebook} target='_blank' rel='noopener noreferrer'>
                                        <FacebookIcon className='space__social-icon display-block height-1dot5rem mw768-height-2rem width-1dot5rem mw768-width-2rem'/>
                                    </a>
                                }
                                {instagram &&
                                    <a href={instagram} target='_blank' rel='noopener noreferrer'>
                                        <InstagramIcon className='space__social-icon display-block height-1dot5rem mw768-height-2rem width-1dot5rem mw768-width-2rem'/>
                                    </a>
                                }
                                {telegram &&
                                    <a href={telegram} target='_blank' rel='noopener noreferrer'>
                                        <TelegramIcon className='space__social-icon display-block height-1dot5rem mw768-height-2rem width-1dot5rem mw768-width-2rem'/>
                                    </a>
                                }
                            </div>
                        }
                        <div className='margin-top-1rem mw768-margin-top-3rem padding-top-2rem padding-bottom-2rem' ref={actionBoxRef}>
                            <ServiceList {...{list: spaceServiceList, theme}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};

export default React.memo(SpaceDescription);